




























































































import { defineComponent } from "@vue/composition-api";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import AssignMemberButton from "@/components/elements/AssignMemberButton.vue";
import FixedActionBar from "@/components/elements/FixedActionBar.vue";
import { clubApi } from "@/lib/backend";
import type { IomodelsLidVoorFeed } from "@/lib/backend/club.api";
import { useAuthStore } from "@/pinia/auth";
import { useCoachStore } from "@/pinia/coach";
import { dagMaandJaar } from "@/utils/date";
import { getIconName, getMemberImage } from "@/utils/images";

export default defineComponent({
	name: "LedenAannemen",
	components: {
		FixedActionBar,
		AssignMemberButton,
	},
	data() {
		return {
			getIconName,
			getMemberImage,
			dagMaandJaar,
			selectedMembers: [] as IomodelsLidVoorFeed[],
		};
	},
	computed: {
		...mapPiniaState(useCoachStore, {
			membersPresentWithoutCoach: "membersPresentWithoutCoach",
			userId: "id",
		}),
		...mapPiniaState(useAuthStore, {
			roles: "roles",
			isClubAanvoerder: "isClubAanvoerder",
		}),
	},
	watch: {},
	created() {
		this.getCoach();
	},
	methods: {
		...mapPiniaActions(useCoachStore, { initCoach: "init", getCoach: "get" }),
		...mapActions("modal", {
			openModal: "openModal",
		}),
		selectItem(event: MouseEvent, id: number) {
			const target = event.target as HTMLElement;
			const checkbox = target as HTMLInputElement;

			if (checkbox) {
				const exist = this.selectedMembers.find((member) => member.id === id);
				if (exist) {
					this.selectedMembers = this.selectedMembers.filter((member) => member.id !== id);
				} else {
					const member = this.membersPresentWithoutCoach.find((member) => member.id === id);
					if (member) this.selectedMembers.push(member);
				}
			}
		},

		reset() {
			this.getCoach();
			this.selectedMembers = [];
		},

		openAanvoerderFlow() {
			const callback = this.reset;
			this.openModal({
				name: "assign-members",
				data: {
					memberIds: this.selectedMembers.map((member) => member.id),
				},
				callback,
			});
		},

		openCoachFlow() {
			const memberIds = this.selectedMembers.map((member) => member.id);
			const _notify = this.$notify;
			const coachId = this.userId;
			const _reset = this.reset;

			async function callback() {
				const body = {
					ledenIds: memberIds,
				};

				try {
					await clubApi.coach.ledenToewijzen(coachId, body);

					_notify({
						title: "Leden zijn toegewezen",
						type: "success",
					});

					_reset();
				} catch (err) {
					_notify({
						title: "Er is iets misgegaan",
						text: (err as unknown as { message: string }).message ?? err,
						type: "error",
					});
				}
			}

			const amount = this.selectedMembers.length;
			this.openModal({
				name: "confirm",
				data: {
					amount: this.selectedMembers.length,
					message: `Weet je zeker dat je ${amount} ${amount === 1 ? "lid" : "leden"} wil aannemen?`,
				},
				callback,
			});
		},

		openAssignMemberModal() {
			if (this.isClubAanvoerder) {
				this.openAanvoerderFlow();
			} else {
				this.openCoachFlow();
			}
		},
	},
});
